import { getFirestore } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";

const db = getFirestore();

export class brokerageFeesAuditedService {
  static async getByFilter(filterData) {
    const ref = collection(db, "monthlyBrokerageFees_Audited");

    let q = query(
      ref,
      where("entryTime", ">=", new Date(filterData.startOf)),
      where("entryTime", "<=", new Date(filterData.endOf))
    );

    if (filterData.member_id) {
      q = query(q, where("member_id", "==", filterData.member_id));
    }

    if (filterData.store) {
      q = query(q, where("store", "==", filterData.store));
    }

    const querySnapshot = await getDocs(q);

    const result = [];
    let top1SetBonusTotal = 0;
    let top2SetBonusTotal = 0;
    let top3SetBonusTotal = 0;
    let allMemberFeesTotal = 0;
    let salaryTotal = 0;
    const allMemberId = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();

      //1. 上1線分潤總和
      if (data.top1.setBonus) {
        top1SetBonusTotal += parseInt(data.top1.setBonus);
      }

      //2. 上2線分潤總和
      if (data.top2.setBonus) {
        top2SetBonusTotal += parseInt(data.top2.setBonus);
      }

      //3. 上3線分潤總和
      if (data.top3.setBonus) {
        top3SetBonusTotal += parseInt(data.top3.setBonus);
      }

      //4. 薪資金額總和
      if (data.salary) {
        salaryTotal += parseInt(data.salary);
      }

      //5. 經濟費用總和
      allMemberFeesTotal += parseInt(data.memberFees);

      //6. 會員數(不重複的)
      if (data.member_id) {
        if (!allMemberId.includes(data.member_id)) {
          allMemberId.push(data.member_id);
        }
      }

      result.push(data);
    });

    // 整個試算結果的各種總計
    const allCalcdTotal = {
      top1SetBonusTotal: top1SetBonusTotal,
      top2SetBonusTotal: top2SetBonusTotal,
      top3SetBonusTotal: top3SetBonusTotal,
      allSetBonusTotal:
        top1SetBonusTotal + top2SetBonusTotal + top3SetBonusTotal,
      allMemberFeesTotal: allMemberFeesTotal,
      salaryTotal: salaryTotal,
      allMemberIdCount: allMemberId.length,
    };

    return { result: result, allCalcdTotal: allCalcdTotal };
  }
}
