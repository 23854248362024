<template>
  <h2>經紀費報表</h2>

  <div class="text-danger mb-3">
    <i class="bi bi-exclamation-circle"></i>
    假如沒有特別設定時間區間，系統會預設帶入這一個月的區間
  </div>

  <div class="mb-5">
    <a
      class="btn btn-secondary mb-3"
      data-bs-toggle="collapse"
      href="#collapseFilter"
      role="button"
      aria-expanded="false"
      aria-controls="collapseFilter"
    >
      查詢條件 <i class="bi bi-funnel"></i>
    </a>

    <div class="collapse" id="collapseFilter">
      <div class="card">
        <div class="card-body">
          <form action="" @submit.prevent="search()">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >時間區間</label
              >
              <div class="input-group mb-3">
                <span class="input-group-text">起始</span>
                <input
                  type="date"
                  class="form-control"
                  v-model="filterData.startOf"
                />
              </div>
              <div class="input-group">
                <span class="input-group-text">結束</span>
                <input
                  type="date"
                  class="form-control"
                  v-model="filterData.endOf"
                />
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label"
                >帳號</label
              >
              <input
                type="text"
                class="form-control"
                v-model="filterData.member_id"
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label"
                >服務店家</label
              >
              <!-- <input
                type="text"
                class="form-control"
                v-model="filterData.store"
              /> -->

              <select class="form-select" v-model="filterData.store">
                <option value="">--無--</option>
                <option v-for="(store, idx) in storeOptions" :key="idx">
                  {{ store }}
                </option>
              </select>
            </div>

            <button type="submit" class="btn btn-primary">搜尋</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-end mb-2">
    <div class="col">
      資料期間：{{ searchingDate.startOf }} ~ {{ searchingDate.endOf }}
    </div>
    <div class="col text-end">
      <button class="btn btn-success mb-1" type="button" v-on:click="onexport">
        <i class="bi bi-file-earmark-spreadsheet"></i> Excel匯出
      </button>
    </div>
  </div>

  <div class="table-responsive table_100vh">
    <table class="table table-bordered table-hover" id="calcedTable">
      <thead class="sticky-top bg-light">
        <tr>
          <td rowspan="2">單號</td>
          <td rowspan="2">會員身份</td>
          <td rowspan="2">服務店家</td>
          <td rowspan="2">編號藝名</td>
          <td rowspan="2">會員帳號 (暱稱)</td>
          <td rowspan="2">平均台數</td>
          <td rowspan="2">上台數</td>
          <td rowspan="2">上班天數</td>
          <td rowspan="2">薪資金額</td>
          <td rowspan="2">經紀費用</td>
          <td rowspan="2">公司經紀費金額</td>
          <td rowspan="2">會員分潤金額</td>
          <td colspan="9">上線會員帳號</td>
        </tr>
        <tr>
          <td>會員</td>
          <td>％</td>
          <td>上一線</td>
          <td>會員</td>
          <td>％</td>
          <td>上二線</td>
          <td>會員</td>
          <td>％</td>
          <td>上三線</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(d, index) in datas" :key="index">
          <td class="wl">{{ String(d.no) }}</td>
          <td class="wm">
            <span v-if="d.agent === true">經紀人</span>
            <span v-else-if="d.role === 'agentCompanyA'">合作經紀公司 A</span>
            <span v-else-if="d.role === 'agentCompanyB'">合作經紀公司 B</span>
            <span v-else>一般</span>
          </td>
          <td class="wl">{{ d.store }}</td>
          <td class="wl">{{ d.numberStageName }}</td>
          <td class="wl">{{ d.member_id }} ({{ d.name }})</td>
          <td class="wl">
            {{
              (Math.floor((d.hoursOnStage / d.daysAtWork) * 100) / 100).toFixed(
                2
              )
            }}
          </td>
          <td class="wm">{{ d.hoursOnStage }}</td>
          <td class="wm">{{ d.daysAtWork }}</td>
          <td class="wm">{{ d.salary }}</td>
          <td class="wm">
            <div class="badge bg-warning">$ {{ d.memberFees }}</div>
          </td>
          <td class="wm">
            <div class="badge bg-warning">
              $ {{ d.memberFees - d.subtotalBonus }}
            </div>
          </td>
          <td class="wm">
            <div class="badge bg-warning">$ {{ d.subtotalBonus }}</div>
          </td>
          <td class="wm">
            <div v-if="d.top1.name">
              {{ d.top1.member_id }} ({{ d.top1.name }})

              <div class="badge bg-secondary" v-if="d.top1.role != 'generally'">
                <div v-if="d.top1.role === 'agent'">經紀人分潤</div>
                <div v-else-if="d.top1.role === 'agentCompanyA'">
                  合作經紀公司A分潤
                </div>
                <div v-else-if="d.top1.role === 'agentCompanyB'">
                  合作經紀公司B分潤
                </div>
              </div>
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div class="badge bg-info" v-if="d.top1.rate || d.top1.rate === 0">
              {{ d.top1.rate }}％
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div
              class="badge bg-warning"
              v-if="d.top1.setBonus || d.top1.setBonus === 0"
            >
              $ {{ d.top1.setBonus }}
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div v-if="d.top2.name">
              {{ d.top2.member_id }} ({{ d.top2.name }})
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div class="badge bg-info" v-if="d.top2.rate || d.top2.rate === 0">
              {{ d.top2.rate }}％
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div
              class="badge bg-warning"
              v-if="d.top2.setBonus || d.top2.setBonus === 0"
            >
              $ {{ d.top2.setBonus }}
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div v-if="d.top3.name">
              {{ d.top3.member_id }} ({{ d.top3.name }})
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div class="badge bg-info" v-if="d.top3.rate || d.top3.rate === 0">
              {{ d.top3.rate }}％
            </div>
            <div v-else>...</div>
          </td>
          <td class="wm">
            <div
              class="badge bg-warning"
              v-if="d.top3.setBonus || d.top3.setBonus === 0"
            >
              $ {{ d.top3.setBonus }}
            </div>
            <div v-else>...</div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <b>總單量：{{ datas.length }} 筆</b>
          </td>
          <td>
            <b>(真實)人數：{{ allCalcdTotal.allMemberIdCount }} 人</b>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div>總計：{{ allCalcdTotal.salaryTotal }}</div>
          </td>
          <td>
            <div>總計：{{ allCalcdTotal.allMemberFeesTotal }}</div>
          </td>
          <td>
            <div>
              總計：{{
                allCalcdTotal.allMemberFeesTotal -
                allCalcdTotal.allSetBonusTotal
              }}
            </div>
          </td>
          <td>
            <div>總計：{{ allCalcdTotal.allSetBonusTotal }}</div>
          </td>
          <td></td>
          <td></td>
          <td>
            <div>總計：{{ allCalcdTotal.top1SetBonusTotal }}</div>
          </td>
          <td></td>
          <td></td>
          <td>
            <div>總計：{{ allCalcdTotal.top2SetBonusTotal }}</div>
          </td>
          <td></td>
          <td></td>
          <td>
            <div>總計：{{ allCalcdTotal.top3SetBonusTotal }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { brokerageFeesAuditedService } from "../../../services/brokerageFeesAuditedService.js";
import { excelExportService } from "../../../services/excelExportService.js";
import { settingService } from "../../../services/settingService.js";
import moment from "moment";
export default {
  data() {
    return {
      datas: [],
      filterData: {},
      searchingDate: {},
      allCalcdTotal: {},
      storeOptions: [],
    };
  },
  methods: {
    onexport() {
      excelExportService.exportBrokerage("calcedTable", "經紀費報表");
    },
    async search() {
      this.searchingDate.startOf = this.filterData.startOf;
      this.searchingDate.endOf = this.filterData.endOf;
      const res = await brokerageFeesAuditedService.getByFilter(
        this.filterData
      );
      this.datas = res.result;
      this.allCalcdTotal = res.allCalcdTotal;
    },
  },
  async created() {
    this.filterData.startOf = moment().startOf("month").format("YYYY-MM-DD");
    this.filterData.endOf = moment().endOf("month").format("YYYY-MM-DD");

    // 服務店家清單
    this.storeOptions = await settingService.getStores();

    this.search();
  },
};
</script>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

#calcedTable .ws {
  min-width: 100px;
}

#calcedTable .wm {
  min-width: 120px;
}

#calcedTable .wl {
  min-width: 170px;
}

#calcedTable thead {
  font-weight: bold;
  font-size: 18px;
}

#calcedTable .badge.bg-warning,
#calcedTable .badge.bg-info {
  font-size: 0.95em;
}

.table_100vh {
  height: 100vh;
}
</style>
