import XLSX from "sheetjs-style";

export class excelExportService {
  /**
   * 匯出經紀費分潤表
   * @param {*} tableId
   */
  static async exportBrokerage(tableId, fileName) {
    const table = document.querySelector("#" + tableId);

    const dataWS = XLSX.utils.table_to_sheet(table);
    console.log("dataWS=>", dataWS);

    const wb = XLSX.utils.book_new();

    // 設定欄位寬度
    dataWS["!cols"] = [];
    for (let i = 1; i <= 21; i++) {
      if (i <= 5) {
        dataWS["!cols"].push({ wpx: 150 });
      } else if (i > 5 && i <= 12) {
        dataWS["!cols"].push({ wpx: 120 });
      } else {
        dataWS["!cols"].push({ wpx: 80 });
      }
    }

    // 設定列的高度
    dataWS["!rows"] = [];
    for (let i = 0; i <= table.querySelectorAll("tr").length - 1; i++) {
      dataWS["!rows"][i] = { hpt: 30 };
    }

    // 設定表頭樣式
    for (let i = 1; i <= 13; i++) {
      const cellName = String(String.fromCharCode(i + 64) + "1");
      dataWS[cellName].s = {
        font: {
          sz: 15,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
    }

    for (let i = 13; i <= 21; i++) {
      const cellName = String(String.fromCharCode(i + 64) + "2");
      dataWS[cellName].s = {
        font: {
          sz: 15,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
    }

    // 調整單號的數字格式，如果沒設定會變成科學記號 E+
    for (let i = 3; i <= table.querySelectorAll("tr").length - 1; i++) {
      dataWS["A" + i].z = "0";
    }

    // 所有cell統一樣式
    for (let i = 1; i <= 21; i++) {
      for (let j = 3; j <= table.querySelectorAll("tr").length - 1; j++) {
        const col = String(String.fromCharCode(i + 64));
        const row = j;
        dataWS[col + row].s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }

    XLSX.utils.book_append_sheet(wb, dataWS, "經紀費分潤資料");

    // export Excel file
    const now = new Date();
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();
    const h = now.getHours();
    const min = now.getMinutes();
    const s = now.getSeconds();

    // download xlsx
    const xlsx_file_name = fileName + "_" + y + m + d + h + min + s + ".xlsx";
    XLSX.writeFile(wb, xlsx_file_name);
  }
}
